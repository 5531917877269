import React from "react";
import { ITableScreen } from "../../interfaces/ITableScreen";
import AccountManager from "../../utils/AccountManager";
import { IAmAccount } from "../am/Models";
import { EScreenMode } from "../../model/app/Enums";
import AccessManager from "../../utils/AccessManager";
import { MenuItems, Roles, WebSites } from "../../utils/Defaults";
import {
	ActionButton,
	Dialog,
	DialogFooter,
	DialogType,
	IContextualMenuItem,
	PrimaryButton,
	TextField,
} from "office-ui-fabric-react";
import TableWrapper, {
	ITableWrapperProps,
	TableConfig,
} from "../../components/table/TableWrapper";
import { ExportCSV } from "../../components/controls/ExportCSV";
import { ETableSource } from "../../components/table/enums/table-source.enum";
import LoaderVf, { ELoaderVfType } from "../../Support/LoaderVf";
import IKeyText from "../../model/app/IKeyText";
import linq from "linq";
import StorageHelper from "../../utils/StorageHelper";
import Global from "../../utils/Global";
import { EControlType } from "../../components/table/enums/control-type.enum";

export interface IAmAccountScreenState extends ITableScreen {
	pivot: string;
	addDialog?: boolean;
	model: IAmAccount;
}

export class AdminTanAuthScreen extends React.Component<
	{},
	IAmAccountScreenState
> {
	private comp: JSX.Element | null = null;

	private am: AccountManager = new AccountManager();

	private current?: IAmAccount;

	constructor(props: any) {
		super(props);

		this.state = {
			mode: EScreenMode.view,
			pivot: "",
			model: Global.getEmptyIdModel(),
		};
	}

	render = () => {
		return (
			<AccessManager roles={[Roles.PortalAdministrator, Roles.FraudMgmt]}>
				{this.state.addDialog ? this.renderAddDialog() : null}
				{this.renderInner()}
			</AccessManager>
		);
	};

	private processContextMenu = (item, e) => {
		if (e.key == MenuItems.edit) {
			//this.setState({mode: EScreenMode.edit, id: item.id, pivot: AmAccountEditPanel.pivotMain});
		} else if (e.key == MenuItems.delete) {
			var m = Global.getEmptyIdModel(item.id);

			this.setState({ model: m }, () => {
				this.patch(true);
			});
		}
	};

	private renderInner = () => {
		let cm: IContextualMenuItem[] = [
			{
				key: MenuItems.delete,
				iconProps: { iconName: "Delete" },
				text: "mTAN entfernen",
			},
		];

		let tc = TableConfig.createInstance({
			//url: "http://localhost:5009/account/query",
			url:
				linq
					.from<IKeyText>(StorageHelper.getWebsites())
					.toArray()
					.filter((x) => x.key == WebSites.am)[0].text + "account/query",
			sourceType: ETableSource.dynamic,
			arrayName: "data",
			sort: [],
			model: { needle: "", paging: TableConfig.initPaging() },
			filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
			dataTransform: (data: any) => {
				return linq
					.from<any>(data)
					.select((x) => {
						return {
							id: x.id,
							name: x.name,
							address: Global.formatAddress(x),
							vbName: x.vbName,
							vlName: x.vlName,
							level: x.level,
							voidL1: x.voidL1,
							voidL2: x.voidL2,
							registrationDate: x.registrationDate,
							tanAuthDateSet: x.properties
								? x.properties.tanAuthDateSet
								: undefined,
						};
					})
					.toArray();
			},
			//staticFilter: (data: any[], needle) => {
			//var d = data as IAmAccount[];
			//debugger;
			//var a = linq
			//.from<any>(d)
			//.where(
			//(x) => x.id.indexOf(needle) >= 0 || x.name.indexOf(needle) >= 0
			//)
			//.toArray();
			//return a;
			//},
			columns: [
				{ name: "id", label: "VOID", sortable: true },
				{ name: "name", label: "Firmenname" },
				{
					name: "adresse",
					label: "Adresse",
					renderer: (r, c) => {
						return <p title={r.address}>{r.address}</p>;
					},
				},
				{ name: "level", label: "Level", sortable: true },
				{ name: "voidL1", label: "L1", sortable: true },
				{ name: "voidL2", label: "L2", sortable: true },
				{ name: "vbName", label: "VB", sortable: true },
				{ name: "vlName", label: "VL", sortable: true },
				{
					name: "registrationDate",
					label: "Anmeldedatum",
					sortable: true,
					renderer: (r, c) => {
						return Global.toGermanDate(r["registrationDate"]);
					},
				},
				{
					name: "tanAuthDateSet",
					label: "mTan Datum",
					sortable: true,
					renderer: (r, c) => {
						return Global.toGermanDate(r["tanAuthDateSet"]);
					},
				},
			],
		});

		let cfg: ITableWrapperProps = {
			id: "tan",
			action: this.processContextMenu,
			onItemChanged: (u) => {
				this.current = u;
			},
			model: { needle: "", statusId: "0", paging: TableConfig.initPaging() },
			contextMenu: cm,
			filter: [{ type: EControlType.text, key: "needle", label: "Suche" }],
			filterAction: (a) => {
				return (
					<>
						<PrimaryButton
							text="Händler hinzufügen"
							onClick={() => {
								this.setState({ addDialog: true });
							}}
						/>

						<ExportCSV csvData={a} fileName={"export"} />
					</>
				);
			},
			config: tc,
		};

		return (
			<>
				{this.state.mode == EScreenMode.loading ||
				(this.state.model && this.state.model.error) ? (
					<LoaderVf
						type={ELoaderVfType.full}
						onClose={(x) => {}}
						message={LoaderVf.parseApiError(
							this.state.model ? this.state.model.error : undefined
						)}
					/>
				) : null}
				<h1>mTAN Validierung</h1>
				<div className="data">
					<TableWrapper {...cfg} />

					{
						//this.state.mode == EScreenMode.edit && AmAccountEditPanel.getPivots().indexOf(this.state.pivot) >=0 ? <AmAccountEditPanel id={this.state.id!} pivot={this.state.pivot} onDismiss={()=>{this.setState({mode: EScreenMode.view});}}/> : null
					}
				</div>
			</>
		);
	};

	private reload = () => {
		let ctrl = Global.GetComponent("et-tan") as TableWrapper;

		if (ctrl) {
			ctrl.reload();
		}
	};

	private renderAddDialog = () => {
		return (
			<Dialog
				hidden={!this.state.addDialog}
				onDismiss={(e) => {
					this.hideAddDialog(e);
				}}
				dialogContentProps={{
					type: DialogType.normal,
					title:
						"Soll der VOID: " +
						this.state.model.id +
						" das mTan Verfahren hinzugefügt werden?",
				}}
				modalProps={{
					isBlocking: true,
					containerClassName: "ms-dialogMainOverride",
				}}
			>
				<div>
					<TextField
						label="VOID"
						name="VOID"
						required
						type="number"
						placeholder={"VOID dem mTan Verfahren hinzufügen."}
						maxLength={8}
						value={this.state.model.id}
						onChange={(e, v) => {
							var m = this.state.model;
							m.id = v!;
							this.setState({ model: m });
						}}
					/>
				</div>

				<DialogFooter>
					<PrimaryButton
						text="Ja"
						onClick={(e) => {
							this.patch(false);
							this.hideAddDialog(e);
						}}
					/>
					<ActionButton
						text="Abbrechen"
						onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							this.hideAddDialog(e);
						}}
					/>
				</DialogFooter>
			</Dialog>
		);
	};

	private hideAddDialog = (e) => {
		this.setState({ addDialog: false, model: Global.getEmptyIdModel() });
	};

	async patch(remove: boolean) {
		var x: any = "";
		var m = this.state.model as IAmAccount;

		m.error = undefined;

		this.setState({ mode: EScreenMode.loading, model: m });

		if (remove) {
			x = await this.am.changeMtanAuth(this.state.model.id, 0);
		} else {
			x = await this.am.changeMtanAuth(this.state.model.id, 1);
		}

		if (x.error) {
			m.error = x.error;
			this.setState({ model: m });
		} else {
			this.setState({ model: x, mode: EScreenMode.view }, () => {
				this.reload();
			});
		}
	}
}

export default AdminTanAuthScreen;
